//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  //props: ['type','show','text','cbr','cbl','confirm'],
  /*
  props: {
    type: {default: null},
    show: {default: false},
    text: {default: ['!!! ALERTA !!!'] },
    cbl:  {default: null},
    cbr:  {default: null},
    confirm: {default: false}
  },
  */
  data: function () {
      return {
          type: null,
          show: false,
          text: ['!!! ALERTA !!!'],
          cbl:  null,
          cbr:  null,
          confirm: false
      }
  },
  

  /*
  data: function () {
      return {
          type: null,
          show: false,
          text: '!!! ALERTA !!!',
          cbl:  null,
          cbr:  null,
          confirm: false
      }
  },
  */
    
  created () {
      this.$bus.$on('ts-confirm', this.set);
  },
  destroyed() {
      this.$bus.$off('ts-confirm', this.set);  
  },
  
  methods: {

    reRender() {
      this.$forceUpdate()
    },
    set(arg) {
        this.show = ( arg.type === null) ?false : true;
        this.type = arg.type;
        this.text = arg.text;
        //this.confirm = arg[0].text;
        if ('confirm' in arg) {
            this.confirm = arg.confirm;
        }
        else {
            this.confirm = false;
        }
        if ('p' in arg) {
            this.p = arg.p;
        }
        else {
            this.p = null;
        }
        if ('cbl' in arg) { 
          this.cbl = arg.cbl; } 

        else { this.cbl = null; }
        if ('cbr' in arg) {  this.cbr = arg.cbr; } 
        else { this.cbr = null; }
        
    },

    close_left() {
      if (this.$util.isNotNull(this.cbl)) {
          this.cbl();
      }
      this.show = false;
    },
    
    close_right() {
      if (this.$util.isNotNull(this.cbr)) {
          this.cbr();
      }            
      this.show = false;
    }

  },
}
